<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                            >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
             </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                ></v-progress-linear>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                clearable
                                solo
                                v-model="office"
                                :items="offices"
                                item-value="office_id"
                                item-text="office"
                                label="Office ID"
                                class="ma-0 pa-0 border-12"
                                hide-details=true>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                clearable
                                solo
                                v-model="entity"
                                :items="entities"
                                item-value="entity_id"
                                item-text="entity_id"
                                label="Entity ID"
                                @change="(event) => updateGroup(event)"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-autocomplete
                                clearable
                                solo
                                v-model="group"
                                :items="groups"
                                item-value="group_id"
                                item-text="group_id"
                                label="Group ID"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="2">
                            <v-menu
                                ref="modal"
                                v-model="modal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        solo
                                        v-model="date"
                                        label="Period"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    type="month"
                                    no-title
                                    @input="modal = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col>
                            <v-btn
                                class="mr-2 border-12"
                                color="info"
                                elevation="2"
                                large
                                @click="submit()"
                                >
                                <v-icon dark>
                                    mdi-magnify
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mb-2">
            <v-col cols="12" xs="12" sm="6" md="12" style="background: #0078d4; margin-top:10px; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
                    <div class="text-white h6" style="padding: 10px">
                        Summary
                    </div>
                <v-expansion-panels
                    v-model="panel"
                    multiple
                    class="border-12">
                <v-expansion-panel v-for="(entity, j) in entities_results" :key="j">
                    <v-expansion-panel-header>{{ entity.entity_id }} <div class="text-right" style="margin-right: 5px">{{ $store.getters.convertToCurrency(entity.total) }}</div></v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-container fluid>
                                <v-row>
                                    <v-col>
                                        <v-card>
                                            <v-list class="transparent">
                                                <v-list-item
                                                    v-for="(group,i) in entity.group"
                                                    :key="i"
                                                >
                                                    <v-list-item-title>{{ group.group_id }}</v-list-item-title>

                                                    <!-- <v-list-item-icon>
                                                        <v-icon>{{ group.summary.drcr }}</v-icon>
                                                    </v-list-item-icon> -->

                                                    <v-list-item-subtitle v-if="group.summary.drcr === 'CR'" class="text-right">
                                                    ({{ group.summary.drcr }}) Amt Debit : {{ $store.getters.convertToCurrency(group.summary.cr)  }}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle v-if="group.summary.drcr === 'DR'" class="text-right">
                                                        ({{ group.summary.drcr }}) Amt Credit : {{ $store.getters.convertToCurrency(group.summary.db)  }}
                                                    </v-list-item-subtitle>
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <v-col cols="12" style="background: #0078d4; margin-top:10px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
            <template>
            <v-card class="text-h6 text-right">
                Total Profit & Loss = {{ $store.getters.convertToCurrency(total_profit_loss) }}
            </v-card>
            </template>
        </v-col>
        <v-col cols="12">
            <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
        </v-col>
        </v-row>
        
        <v-row class="mb-15">
            <v-card color="blue lighten-5">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-card-title>
                            Result
                              <v-row justify="end">
                                    <v-col cols="12" xs="12" sm="12" md="2" class="text-right">
                                        <v-btn class="mt-5 mr-2 text-white" 
                                        color="#0D47A1" elevation="2" medium>
                                        <download-excel
                                        class="text"
                                        :fetch           = "exportExcel"
                                        :fields="headersColumn"
                                        :before-generate = "startDownload"
                                        :before-finish   = "finishDownload">
                                        Export Excel
                                        </download-excel> 
                                        <v-icon>mdi-arrow-down-bold-box</v-icon>
                                    </v-btn>
                                </v-col>
                                    </v-row>
                                <!-- <v-text-field
                                    v-model="$store.state.text"
                                    append-icon="mdi-magnify"
                                    label="Search..."
                                    single-line
                                    @keyup.enter="search"
                                    hide-details
                                ></v-text-field> -->
                        </v-card-title>
                        <v-data-table
                                :headers="headers"
                                :items="invoices"
                                :options.sync="options"
                                :loading="loading"
                                loading-text="Please wait, retrieving data"
                                :server-items-length="totalItems"
                                :page.sync="pagination"
                                page-count="10"
                                class="elevation-1"
                                >
                              <template v-slot:[`item.cr`]="{ item }">
                                    <div v-if="item.drcr === 'CR'">
                                        {{ $store.getters.convertToCurrency(item.cr) }}
                                    </div>
                                    <div v-else>
                                    </div>
                                </template>
                              <template v-slot:[`item.dr`]="{ item }">
                                    <div v-if="item.drcr === 'DR'">
                                        {{ $store.getters.convertToCurrency(item.dr) }}
                                    </div>
                                    <div v-else>
                                    </div>
                                </template>        
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        </v-row>
        
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            panel: [],
            disabled: false,
            readonly: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Griya Kusuma',
                disabled: false,
                href: '/admin/gk',
                },
                {
                text: 'Information',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Period Jurnal Year',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                
            ],
            loading: false,
            loading_loader: false,
            offices: [],
            office: '',
            entities: [],
            entity: '',
            date: '',
            menu: false,
            modal: false,
            groups: [],
            group: '',
            period: '',
            options: {},
            display: 'none',
            total_piutang: 0,
            total_hutang: 0,
            total_paid: 0,
            headers: [
                {
                    text: 'Entity ID',
                    align: 'start',
                    sortable: true,
                    value: 'entity_id',
                },
                { text: 'Group ID', value: 'group_id' },
                { text: 'AC Num', value: 'ac_num' },
                { text: 'AC Par', value: 'ac_par' },
                { text: 'DrCr', value: 'drcr' },
                { text: 'Ac Name', value: 'ac_name' },
                { text: 'Amount Debit', value: 'cr' },
                { text: 'Amount Credit', value: 'dr' }
            ],
            headersColumn: {
                'Office ID':'office_id',
                'Entity id': 'entity_id',
                'Group': 'group_id',
                'Ac Num' : 'ac_num',
                'Ac Par': 'ac_par',
                'Dr Cr': 'drcr',
                'Ac Name': 'ac_name',
                'Dr': 'dr',
                'Cr': 'cr',
                },
            invoices: [],
            totalItems: 10,
            pagination: 1,
            entities_results: [],
            total_profit_loss: 0
        }
    },
    mounted(){
     this.getOffice() 
     this.getEntity()
     this.getGroup()  

    },
    methods:{
        startDownload(){
                this.loading = true
                this.disabledExport=true
                },
        finishDownload(){
                this.loading = false
                this.disabledExport=false
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/griya/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.offices = res.data
                this.office = res.data[0].office_id
            });
        },
        async getEntity(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/journal?entity_id=${this.entity ? this.entity : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.entities = res.data
            });
        },
        async getGroup(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/journal/group?entity_id=${this.entity ? this.entity : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.groups = res.data
            });
        },
        updateGroup(value){
            this.entity = value
            this.getGroup()
        },
        search(){
            this.invoices = []
            this.pagination = 1
            this.submit(1, 10)
        },
    
    async exportExcel() {
        if (this.date === '') {
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please fill period",
                  visible: true
                };
             return false;
        } else {
            this.period = new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"")
            const response = await axios.get(`${process.env.VUE_APP_URL}/api/griya/journal/GetExcel?search=${this.$store.state.text}&entity_id=${this.entity ? this.entity : ''}&group_id=${this.group ? this.group : ''}&period=${this.period ? this.period : ''}`, {
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            });
            console.log(response.data);
            return response.data
            
        }

    },  
       async submit(page = 1, itemsPerPage = 10){  
            if (this.date === '') {
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please fill period",
                  visible: true
                };
                return false;
            }

            this.period = new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"")

            this.loading = true
            this.loading_loader = true

            this.getSummary()

            await axios.get(`${process.env.VUE_APP_URL}/api/griya/journal?search=${this.$store.state.text}&entity_id=${this.entity ? this.entity : ''}&group_id=${this.group ? this.group : ''}&period=${this.period ? this.period : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
             //   console.log(res.data)
                this.loading = false
                this.loading_loader = false
                this.invoices = res.data.data
                this.totalItems = res.data.total
                this.pagination = res.data.current_page
            })
        }, 
        async getSummary(){
            await axios.get(`${process.env.VUE_APP_URL}/api/griya/journal/summary?entity_id=${this.entity ? this.entity : ''}&group_id=${this.group ? this.group : ''}&period=${this.period ? this.period : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data)
                this.display = 'block'
                this.entities_results = res.data.data
                this.total_profit_loss = res.data.total_profit_loss
            })
        }
    },
     watch: {
        options: {
        handler () {
            // this.loading = true
            const { page, itemsPerPage } = this.options
            if (this.period != '') {
                this.submit(page, itemsPerPage)
            }
        },
        deep: true
      }
    }
    

}
</script>

<style>

</style>